$turquoise: #1B2A5D;
$primary: #000633;
$gap: 32px;
$container-offset: (2 * $gap);
$white-ter: hsl(0, 0%, 96%);
$text-light: $white-ter;
$grey-lighter: hsl(0, 0%, 86%);
$text-strong: $grey-lighter;
$grey-dark: hsl(0, 0%, 29%);
$title-color: $turquoise;
$navbar-background-color: $primary;
$navbar-item-color: $white-ter;
$cyan: hsl(204, 86%, 53%);
$info: $cyan;
$blue: hsl(217, 71%, 53%);
$navbar-dropdown-arrow: $white-ter;
$footer-background-color: $white-ter;
$family-sans-serif: "Chivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$title-family: "Lora", serif;
$size-2: 2.5rem;
$size-3: 2rem;
$title-size: $size-2;
$size-5: 1.25rem;
$size-normal: $size-5;
$size-4: 1.5rem;
$size-medium: $size-4;
$size-large: $size-3;
$content-heading-color: false;